<template>
<div class="article">
    <div class="header">
        <a-icon class="back" @click="$router.go(-1)" type="left" />
        <span class="title">{{ article.title }}</span>
    </div>

    <a-spin v-if="loading" />
    <div v-else class="box" v-html="article.content">
    </div>

</div>
  
</template>
<script>
export default {
  name: "Article",
  data() {
    return {
      loading: false,
      article_id: this.$route.params.id,
      article:{title:'',content:''}
    };
  },
  components:{
  },
  mounted() {
      this.getData();
  },
  methods: {
    getData(){
        this.loading = true;
        if(!this.article_id || !/^\d+$/.test(this.article_id)){
          this.$router.push({name:'profile'});
        }
        this.$http.call(this.$http.api.policy,{
            data:{id: this.article_id}
        }).then(res=>{
            console.log(res);
            this.loading = false;
            let resp = res.data;
            if(resp.code=='200'){
                this.article = resp.data;
            }else{
                this.$message.error(resp.data);
            } 
            
        },function(res){
            console.log('something goes wrong...');
            console.log(res);
        }).then(()=>{
            
        });
    },
  },
};
</script>
<style scoped>
.article{
    padding: 30px;
    width: 100%;
}
.article .box {
    border-radius: 4px;
    margin-top: 40px;
    padding: 15px;
    position:relative;
    text-align: center;
    
}
.article .box >>> img{
max-width: 100%;
}
.article .box >>> table{
max-width: 100%;
overflow: auto;
}
</style>
